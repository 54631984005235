import { requestApi } from '../api/api';
import { getScannerToken } from '../api/getToken';

export async function getStatus(scannerId: string){
    const token = getScannerToken(scannerId);
    if(token === null){
        return false;
    }
    const url = `/scanners/${scannerId}/status`;
    const response = await requestApi(url, 'GET', null, token);
    if(response.status!==200){
        return false;
    }
    return await response.json();
}
    