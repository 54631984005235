import { getScannerToken } from '../api/getToken';
import { requestApi } from '../api/api';

export async function doCheckin(scannerId: string, card: string){
    const token = getScannerToken(scannerId);
    if(token === null){
        return {
            success: false,
            message: "Scanner not found"
        }
    }
    const url = `/attendances/attend`;
    const body = {
        card: card
    }
    const response = await requestApi(url, 'POST', body, token);
    if(response.status!==201){
        return {
            success: false,
            message: (await response.json()).message
        }
    }
    return {
        success: true,
        data: await response.json()
    }
}

