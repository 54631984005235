import { requestApi } from '../api/api';
import { getScannerToken } from '../api/getToken';

export async function doHealthcheck(scannerId: string){
    const token = getScannerToken(scannerId);
    if(token === null){
        return false;
    }
    const url = `/scanners/${scannerId}/healthcheck`;
    let response = await requestApi(url, 'PATCH', null, token);
    if(response.status!==200){
        return false;
    }
    console.log(response.status);
    return await response.json();
}