import * as React from 'react';
import BuildDate from '../components/BuildDate';

export default function Help() {
  return (
    <div>
      <h1 className='font-bold text-4xl'>Help</h1>
      <p>
        This is the help page for the DHBW StudiCheckIn Scanner Simulator.
      </p>
      <h2 className='font-bold text-3xl' id='scanner-student-sel'>Scanner / Student Selector</h2>
      <p>
        This simulator for the Scanners of DHBW StudiCheckIn allows you to use a sccanner without having the physical hardware.
        For this purpose, it allows you to select a scanner to simulate and also a student to check in. Both of these selections
        rely on data, that isn't available using the api of DHBW StudiCheckIn. Therefore, this simulator can't dynamically fetch
        the available scanners and students. Instead, it uses a static file that was created at the time of the last build. This
        means that the scanners and students that are available in this simulator are the ones that were registered in the system
        at the time of the last build. Meaning, that the provided data might not be up to date.
      </p>
      <BuildDate />
    </div>
  );
}