import Status from './Status';
import Healthcheck from './Healthcheck';
import CheckIn from './CheckIn';
import "./scanner.css"
import BuildDate from '../components/BuildDate';

export async function loader({ params }: { params: any}) {

    return { scannerId: params.id };
}

export default function Scanner() {
    return (
        <div>
            <h1 className='font-bold text-4xl'>Scanner</h1>
            <div className='grid-cols-2 grid-rows-2 grid'>
                <Status></Status>
                <Healthcheck></Healthcheck>
                <CheckIn></CheckIn>
            </div>
            <BuildDate/>
        </div>
    )
}