import * as React from 'react';
import preval from 'preval.macro';

export default function BuildDate() {
    const buildDate = new Date(preval`module.exports = new Date()`)
    const string = buildDate.getDate() + "." + (buildDate.getMonth() + 1) + "." + buildDate.getFullYear() + " " + buildDate.getHours() + ":" + buildDate.getMinutes() + ":" + buildDate.getSeconds();
    return (
        <p className="text-center text-gray-500 text-xs absolute bottom-0 left-0 right-0">
            Built on {string}
        </p>
    )
}