import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import * as React from "react";
import { getStatus } from "./getStatus";
import { useLoaderData } from "react-router-dom";

export default function Status() {
    const { scannerId } = useLoaderData() as { scannerId: string };
    React.useEffect(() => {
        function updateStatus(){
            getStatus(scannerId).then((status)=>{
                if(status){
                    document.getElementById('status-status')!.textContent = statusToString(status.status);
                    document.getElementById('status-valid-from')!.textContent = (new Date(status.startTime)).toLocaleString();
                    document.getElementById('status-valid-to')!.textContent = (new Date(status.endTime)).toLocaleString();
                    if(status.lesson){
                        document.getElementById('status-lecture')!.textContent = status.lesson.name;
                        document.getElementById('status-lecture-start')!.textContent = status.lesson.startsAt;
                        document.getElementById('status-lecture-end')!.textContent = status.lesson.endsAt;
                    } else {
                        document.getElementById('status-lecture')!.textContent = 'Keine Vorlesung';
                        document.getElementById('status-lecture-start')!.textContent = '';
                        document.getElementById('status-lecture-end')!.textContent = '';
                    }
                } else {
                    document.getElementById('status-status')!.textContent = 'Fehler';
                }
            });
        }
        updateStatus();
        setInterval(updateStatus, 60000);
    });
    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold">Status</h2>
            <Table className="status">
                <TableBody>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell id="status-status">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gültig von</TableCell>
                        <TableCell id="status-valid-from">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gültig bis</TableCell>
                        <TableCell id="status-valid-to">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Vorlesung</TableCell>
                        <TableCell id="status-lecture">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Beginnt um</TableCell>
                        <TableCell id="status-lecture-start">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Endet um</TableCell>
                        <TableCell id="status-lecture-end">Unbekannt</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}

function statusToString(status: number){
    switch(status){
        case 0: return 'Inaktiv';
        case 1: return 'Aktiv';
        case 2: return 'Schlafend';
        default: return 'Unbekannt';
    }
}