import { basePath } from '../config';


export async function requestApi(path: string, method: string, body: any, token: string){
    if(method === 'GET'){
        return fetch(`${basePath}${path}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    }
    return fetch(`${basePath}${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: body===null ? null :JSON.stringify(body)
    });
}