import { requestApi } from "./api";
import { getScannerToken } from "./getToken";

export async function authScanner(scannerId: string){
    const token = getScannerToken(scannerId);
    if(token === null){
        return false;
    }
    const response = await requestApi(`/auth/scanner`, 'GET', null, token);
    if(response.status === 200){
        return true;
    }
    return false;
}