import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Help from './help/Help';
import Scanner, {loader as scannerLoader} from './scanner/Scanner';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([{
  path: '/',
  element: <App />,
},
{
  path: '/help',
  element: <Help />,
},
{
  path: '/scanner/:id',
  element: <Scanner/>,
  loader: scannerLoader
}]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

