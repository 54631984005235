import React from 'react';
import './App.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions, DialogContentText, Link, MenuItem, Select, Alert, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';import * as scanners from "./scanners.json"
import { authScanner } from './api/authScanner';
import BuildDate from './components/BuildDate';


function App() {
  const scannerValues = Object.values(scanners);
  const [scanner, setScanner] = React.useState(scannerValues[0].id);
  const [authFailed, setAuthFailed] = React.useState(false);


  async function startScanner(){
    if(await authScanner(scanner) === false){
      setAuthFailed(true);
      return;
    }
    window.location.href = "/scanner/" + scanner;
  }
  return (
    <div>
      <Dialog open={!authFailed}>
        <DialogTitle>DHBW StudiCheckIn Scanner Simulator</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can simulate the scanner for the DHBW StudiCheckIn.<br></br>
            Please select a scanner to simulate.
          </DialogContentText>
          <Select
            id="scanner-select"
            label="Scanner"
            defaultValue={scannerValues[0].id}
            onChange={(event) => setScanner(event.target.value)}
            >
            {scannerValues.map((scanner) => {
              return <MenuItem value={scanner.id}>{scanner.name}</MenuItem>
            })}
          </Select>
          <DialogContentText>
            <b>Important:</b> The scanners you can select here are the ones that were registered in the system at the time of the last build.
            For more information, <Link href="/help#scanner-student-sel">click here</Link>.
          </DialogContentText>
          <DialogActions>
            <button onClick={startScanner}>Start</button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <BuildDate/>
      {authFailed &&
        <Alert severity="error"
        action={
          <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAuthFailed(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
        }><AlertTitle>Error</AlertTitle>
        Scanner could not be authenticated. This error likely occured because the API doesn't know this scanner anymore.</Alert>
      }
    </div>
  );
}

export default App;

// <BearerForm />
// <ScannerStatus />
// <Healthcheck />