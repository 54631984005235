import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import * as students from '../students.json';
import { InputLabel, MenuItem, Select, Button, Alert, AlertTitle, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Link } from '@mui/material';
import { Close } from '@mui/icons-material';
import { doCheckin } from './doCheckin';

export default function Status() {
    const { scannerId } = useLoaderData() as { scannerId: string };
    const [student, setStudent] = React.useState('');
    const [alertVisible, setAlertVisible] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [studentFirstName, setStudentFirstName] = React.useState('');
    const [studentLastName, setStudentLastName] = React.useState('');
    const [studentTwoFactor, setStudentTwoFactor] = React.useState(null);
    const studentValues = Object.values(students);
    async function checkInStudent(){
        setAlertVisible(false);
        if(student === ''){
            setAlertMessage('Bitte wählen Sie einen Studenten aus.');
            setAlertVisible(true);
        }
        const result = await doCheckin(scannerId, student);
        if(!result.success){
            setAlertMessage(result.message);
            setAlertVisible(true);
            return;
        } else {
            setStudentFirstName(result.data.student.firstName);
            setStudentLastName(result.data.student.lastName);
            if(result.data.attendance.secondFactorType === "CODE"){
                setStudentTwoFactor(result.data.attendance.secondFactorToken);
            } else {
                setStudentTwoFactor(null);
            }
            setDialogVisible(true);
        }
    }

    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold">CheckIn</h2>
            {alertVisible&& <Alert severity="error" 
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setAlertVisible(false);
                        }}
                    >
                        <Close fontSize="inherit"/>
                    </IconButton>
                }
            >
                <AlertTitle>Error</AlertTitle>
                {alertMessage}
            </Alert>}
            {dialogVisible&& <Dialog open={dialogVisible}>
                <DialogTitle>Student einchecken</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        CheckIn erfolgreich für {studentFirstName} {studentLastName}.
                    </DialogContentText>
                    {studentTwoFactor && <DialogContentText>
                        Zwei-Faktor-Code: {studentTwoFactor}
                    </DialogContentText>}
                    {!studentTwoFactor && <DialogContentText>
                        Kein Zwei-Faktor-Code erforderlich.
                        </DialogContentText>}
                    <DialogActions>
                        <Button onClick={() => setDialogVisible(false)}>OK</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>}
                
            <InputLabel id="student-label">Student</InputLabel>
            <Select
                labelId='student-label'
                id="student-select"
                onChange={(event) => setStudent(event.target.value)}
                defaultValue=""
            >
                    {studentValues.map((student) => {
                        return <MenuItem value={student.card}>{student.firstName} {student.lastName}</MenuItem>
                    })}
            </Select><br></br><br></br>
            <Button variant="contained" onClick={checkInStudent}>Student einchecken</Button><br></br> <br></br>
            <b>Important:</b> The students you can select here are the ones that were registered in the system at the time of the last build.
            For more information, <Link href="/help#scanner-student-sel">click here</Link>.
        </div>
    )
}