import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import * as React from "react";
import { doHealthcheck } from "./doHealthcheck";
import { useLoaderData } from "react-router-dom";

export default function Healthcheck() {
    const { scannerId } = useLoaderData() as { scannerId: string };
    React.useEffect(() => {
        async function healthcheck(){
            const health = await doHealthcheck(scannerId);
            if(health){
                document.getElementById('healthcheck-status')!.textContent = 'OK';
                document.getElementById('healthcheck-next')!.textContent = (new Date(health.nextHealthcheck)).toLocaleString();
                setTimeout(healthcheck, new Date(health.nextHealthcheck).getTime() - Date.now());
            } else {
                document.getElementById('healthcheck-status')!.textContent = 'Fehler';
                document.getElementById('healthcheck-next')!.textContent = '';
                setTimeout(healthcheck, 1000);
            }
        }
        healthcheck();
    });
    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold">Healthcheck</h2>
            <Table className="healthcheck">
                <TableBody>
                    <TableRow>
                        <TableCell>Zustand</TableCell>
                        <TableCell id="healthcheck-status">Unbekannt</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Nächster Healthcheck</TableCell>
                        <TableCell id="healthcheck-next">Unbekannt</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}